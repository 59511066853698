import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { ProductCodeDto, ProductCodeService, ProductService } from 'src/api';
import { getErrorMessage } from 'src/utils/error';

export interface ProductCodeState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  codes: ProductCodeDto[];
  currentCode?: ProductCodeDto | null;
  productsEmpty: boolean;
}

const initialState: ProductCodeState = {
  codes: [],
  currentCode: null,
  loading: false,
  hasError: false,
  error: '',
  productsEmpty: true,
};

export const productCodeSlice = createSlice({
  name: 'productCode',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    clearError: (state) => {
      state.hasError = false;
      state.error = '';
    },
    update: (state, action: PayloadAction<ProductCodeDto>) => {
      state.codes = state.codes.map((code) =>
        code.id === action.payload.id ? action.payload : code
      );
    },
    add: (state, action: PayloadAction<ProductCodeDto>) => {
      state.codes.push(action.payload);
    },
    remove: (state, action: PayloadAction<string>) => {
      state.codes = state.codes.filter((code) => code.id !== action.payload);
    },
    setCodes: (state, action: PayloadAction<ProductCodeDto[]>) => {
      state.codes = action.payload;
      state.productsEmpty = action.payload.length === 0;
    },
    setCurrentCode: (state, action: PayloadAction<ProductCodeDto>) => {
      state.currentCode = action.payload;
    },
  },
});

export const { loading, hasError, update, remove, add, setCodes, setCurrentCode, clearError } =
  productCodeSlice.actions;

export const getProductCodes = (merchantId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const data = await ProductCodeService.getApiProductcode(merchantId);
    dispatch(setCodes(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
    throw e;
  }
};

export const getProductCodesByProduct =
  (productId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const data = await ProductService.getApiProductCodes(productId);
      dispatch(setCodes(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const getProductCode =
  (productCodeId: string): AppThunk =>
  async (dispatch) => {
    try {
      const data = await ProductCodeService.getApiProductcode1(productCodeId);
      dispatch(setCurrentCode(data));
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const createProductCode =
  (productCode: ProductCodeDto): AppThunk =>
  async (dispatch) => {
    try {
      const data = await ProductCodeService.postApiProductcode(productCode);
      dispatch(add(data));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const removeProductCode =
  (productCodeId: string): AppThunk =>
  async (dispatch) => {
    try {
      const data = await ProductCodeService.deleteApiProductcode(productCodeId);
      dispatch(remove(productCodeId));
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const updateProductCode =
  (productCodeId: string, productCode: ProductCodeDto): AppThunk =>
  async (dispatch) => {
    try {
      const data = await ProductCodeService.putApiProductcode(productCodeId, productCode);
      dispatch(update(data));
      return true;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e;
    }
  };

export const updateProductCodes =
  (id: string, productCodes: ProductCodeDto[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearError());
      const result = await ProductService.postApiProductCodes(id, productCodes);
      dispatch(setCodes(result));
      return result;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const productCodeStateSelector = (state: RootState): ProductCodeState => state.productCode;

export default productCodeSlice.reducer;
